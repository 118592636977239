<template>
  <v-row :class="`gw-report__${report}`" class="gw-report">
    <v-col cols="12">
      <slot
        :apply="onFind"
        :filterKey="sFilterKey"
        :loading="loading"
        :setColumns="onSelectHeaders"
        :setData="setFilterData"
        name="filters"
      />
    </v-col>
    <v-col v-if="applied" cols="12">
      <template v-if="sPdfUrl">
        <report-toolbar
          :download-xls="!!sXlsUrl"
          :downloading="downloading"
          :title="reportTitle"
          @download="download"
        />
        <object
          :data="sPdfUrl"
          type="application/pdf"
          width="100%"
          height="650"
        />
      </template>

      <report-group
        v-else-if="arItemList?.items"
        :headers="arHeaders"
        :hide-content="!showContent"
        :item="arItemList"
      >
        <template #top>
          <report-toolbar
            :download-pdf="canDownloadPdf"
            :download-xls="canDownloadXls"
            :downloading="downloading"
            :title="reportTitle"
            @download="download"
          />
        </template>
      </report-group>

      <v-data-table
        v-else
        :key="sTableKey"
        :group-by="sGroupBy"
        :headers="arHeaders"
        :items="showContent ? arItemList : []"
        :loading="loading"
        :options="pagination"
        :server-items-length="serverItemsLength"
        class="dt-listing"
        dense
        disable-sort
        hide-default-footer
      >
        <template #top>
          <report-toolbar
            :download-pdf="canDownloadPdf"
            :download-xls="canDownloadXls"
            :downloading="downloading"
            :title="title"
            @download="download"
          />
        </template>

        <template v-slot:group.header="{ group, headers, toggle, isOpen }">
          <td :colspan="headers.length">
            <v-btn :ref="group" :data-open="isOpen" icon small @click="toggle">
              <v-icon v-if="isOpen">mdi-chevron-up</v-icon>
              <v-icon v-else>mdi-chevron-down</v-icon>
            </v-btn>
            <span class="font-weight-bold ml-4" v-text="group" />
          </td>
        </template>

        <template #[`item.${sOrderNumberHeader}`]="{ item }">
          <invoice-number-btn :invoice="item" />
        </template>

        <template #foot>
          <report-table-footer
            v-if="!$_.isEmpty(obTotalData) && !loading"
            :headers="arHeaders"
            :last-page="serverLastPage"
            :total="serverItemsLength"
            :totals="obTotalData"
            :value="currentPage"
            @input="paginate"
          />
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { Component, Mixins, Prop } from "vue-property-decorator";
import type { DataTableHeader } from "@/mixins/DataTableMixin";
import DataTableMixin from "@/mixins/DataTableMixin";
import PaginateMixin from "@/mixins/PaginateMixin";
import type { InvoiceData } from "@planetadeleste/vue-mc-gw";
import {
  InvoiceCollection,
  TaxTypeCollection,
} from "@planetadeleste/vue-mc-gw";
import { AppModule } from "@/store/app";
import type {
  ReportFormat,
  ReportFormatDownloadable,
  ReportType,
  ReportResponse,
} from "@/composables/report";
import useReport, { type ReportCollectResponse } from "@/composables/report";
import {
  camelCase,
  cloneDeep,
  find,
  get,
  has,
  isEmpty,
  isObjectLike,
  map,
  set,
  uniqueId,
  upperFirst,
} from "lodash";
import { currencyFormat, eqJson } from "@/plugins/helpers";
import ReportRow from "@/modules/reports/components/ReportRow.vue";
import ReportTableFooter from "@/modules/reports/components/ReportTableFooter.vue";
import { EventBus } from "@/services/event-bus";
import { debug } from "@/composables/debug";
import ReportGroup from "@/modules/reports/components/ReportGroup.vue";
import InvoiceNumberBtn from "@/modules/invoices/components/InvoiceNumberBtn.vue";
import { usePusher } from "@/services/pusher";
import type { Channel } from "pusher-js";
import type { StoreReportChannel } from "@/types/broadcast";
import ReportToolbar from "@/modules/reports/components/ReportToolbar.vue";
import ReportFilterMixin from "@/modules/reports/mixins/ReportFilterMixin";
import type { FilterData } from "@/modules/reports/components/ReportFilters.vue";
import Utils from "@/services/Utils";

@Component({
  components: {
    ReportToolbar,
    InvoiceNumberBtn,
    ReportGroup,
    ReportTableFooter,
    ReportRow,
  },
})
export default class Report extends Mixins(
  DataTableMixin,
  PaginateMixin,
  ReportFilterMixin
) {
  @Prop({ type: String, required: true }) readonly report!: ReportType;
  @Prop({ type: String, default: "Report" }) readonly filename!: string;
  @Prop({ type: String, default: "Report" }) readonly title!: string;

  obPusher = usePusher();
  obStoreReportChannel: Channel | null = null;

  obCollection: InvoiceCollection = new InvoiceCollection();
  obTaxTypeCollection: TaxTypeCollection = new TaxTypeCollection();
  obTotalData: Record<string, Record<string, any>> = {};
  obDownloadReady: Record<ReportFormatDownloadable, boolean> = {
    xls: false,
    pdf: false,
    csv: false,
  };
  arItemList:
    | ReportCollectResponse
    | Record<string, any>
    | Record<string, any>[] = {};
  downloading: string | null = null;
  applied = false;
  withTaxes = false;
  loading = false;
  sMethod = "find";
  sGroupBy: string | null | undefined = null;
  sTableKey: string | null | undefined = null;
  sTitle: string | null | undefined = null;
  sFilename: string | null | undefined = null;
  sCacheKey: string | null | undefined = null;
  sPdfUrl: string | undefined | null = undefined;
  sXlsUrl: string | undefined | null = undefined;

  get canDownload() {
    return this.loading || !this.obDownloadReady.xls;

    /*if (isArrayLike(this.arItemList) && !has(this.arItemList, "items")) {
      return this.arItemList.length > 0;
    }

    return (
      isObjectLike(this.arItemList) &&
      has(this.arItemList, "items") &&
      !isEmpty(get(this.arItemList, "items", []))
    );*/
  }

  get canDownloadPdf() {
    return this.canDownload && this.obDownloadReady.pdf;
  }

  get canDownloadXls() {
    return this.obDownloadReady.xls;
  }

  get reportTitle() {
    return this.sTitle || this.title;
  }

  get reportFilename() {
    return this.sFilename || this.filename;
  }

  get sOrderNumberHeader() {
    return this.$t("invoice.dgi.number");
  }

  get sFilterKey(): string {
    const sFilterPart = upperFirst(camelCase(this.report));

    return `Report${sFilterPart}`;
  }

  get showContent() {
    return !!this.obFilterData?.show_content;
  }

  created() {
    this.obStoreReportChannel = this.obPusher.channel(
      "report",
      "store",
      this.onEnableDownloadBtn
    );
  }

  beforeDestroy() {
    if (this.obStoreReportChannel) {
      this.obStoreReportChannel.disconnect();
    }
  }

  mounted() {
    this.updateTableKey();
    this.pagination.itemsPerPage = 20;
    this.$nextTick(() => {
      if (this.obTaxTypeCollection.isEmpty()) {
        this.obTaxTypeCollection.filterBy({ company: AppModule.company.id });
        this.obTaxTypeCollection.fetch();
      }
    });
  }

  async onFind(obData?: Partial<FilterData>) {
    this.currentPage = 1;
    await this.find(obData, true);
  }

  async find(obData?: Partial<FilterData>, bInit = false): Promise<void> {
    if (this.loading) {
      return;
    }

    this.loading = true;

    if (!isObjectLike(obData) || isEmpty(obData)) {
      obData = cloneDeep(this.obFilterData);
    }

    if (this.currentPage === 1) {
      this.applied = false;
    }

    set(obData, "initialize", 0);
    EventBus.emit("report.before.apply", obData);

    if (bInit) {
      this.sCacheKey = crypto.randomUUID();
      this.obDownloadReady.pdf = false;
      this.obDownloadReady.xls = false;
      this.sPdfUrl = null;
      this.sXlsUrl = null;
      this.sTitle = null;
      this.sFilename = null;
      set(obData, "initialize", 1);
      set(obData, "cachekey", this.sCacheKey);
    }

    this.setFilterData(obData);

    // FIND DIRECT ON REPORT CONTROLLER
    const obReport = useReport(this.report, "pdf");
    const arColumns = map(this.headers, "value");
    obReport.filters(obData);
    obReport.columns(arColumns);
    obReport.report
      .setPage(this.currentPage)
      .setItemsPerPage(this.pagination.itemsPerPage ?? 15);

    const obResponse = await obReport.request<ReportResponse>();

    if (!obResponse) {
      this.loading = false;
      return;
    }

    if (get(obResponse, "message") == "redirect") {
      this.$router.push({ name: "reportjob.list", query: { msg: "1" } });
    }

    this.sPdfUrl = obResponse.pdf;
    this.sXlsUrl = obResponse.xls;
    this.sTitle = obResponse.title;
    this.sFilename = obResponse.filename;

    /*const obResponse = await obReport.collect();

    if (obResponse?.items && obResponse?.columns?.length) {
      this.arItemList = obResponse.items.data || obResponse.items;
      this.mapPagination(obResponse.items);

      // Set headers
      this.obReportHeaders.value = map(obResponse.columns, (sColumn) => {
        // const obRow = this.arItemList[0];
        return {
          text: sColumn,
          value: sColumn,
          translated: true,
          groupable: false,
          align: obResponse?.columns_right?.includes(sColumn) ? "end" : "start",
        };
      });

      // Set group by
      if (obResponse.group_by) {
        this.sGroupBy = obResponse.group_by;
        this.pagination.groupBy = [this.sGroupBy];
        // this.arHeaders.push({
        //   text: this.sGroupBy,
        //   value: this.sGroupBy,
        //   groupable: true,
        // });
      }

      // Set title
      this.sTitle = get(obResponse, "title", this.title) as string;

      // Set filename
      this.sFilename = get(obResponse, "filename", this.filename) as string;

      // Set totals
      const obTotal = { total: obResponse.totals };
      this.obTotalData = isEmpty(obResponse.groups)
        ? obTotal
        : assign(obResponse.groups, obTotal);
    }*/

    this.updateTableKey();
    this.applied = true;
    this.loading = false;
  }

  onSelectHeaders(arValue: DataTableHeader[]) {
    this.withTaxes = false;

    if (find(arValue, { value: "taxes" })) {
      this.withTaxes = true;
      // arValue = filter(arValue, (obItem) => obItem.value !== "taxes");
    }

    this.setDTHeaders(arValue);

    if (this.applied) {
      this.find();
    }
  }

  onEnableDownloadBtn(obData: StoreReportChannel) {
    if (
      obData.cachekey !== this.sCacheKey ||
      !has(this.obDownloadReady, obData.filetype)
    ) {
      return;
    }

    set(this.obDownloadReady, obData.filetype, true);
  }

  setFilterData(obData: FilterData) {
    const obFilterData = cloneDeep(obData);
    set(obFilterData, "signed", 1);
    set(obFilterData, "notRejected", 1);

    if (!obFilterData.movementTypeCode) {
      set(obFilterData, "movementTypeCode", [1, 2, 5, 6]);
    }

    // if (obFilterData.currency_convert && obFilterData.convert) {
    //   set(obFilterData, "convert", obFilterData.currency_convert);
    //   // unset(obFilterData, "currency");
    // } else
    if (obFilterData.currency && !obFilterData.currency_convert) {
      set(obFilterData, "currency_convert", obFilterData.currency);
    }

    if (eqJson(obFilterData, this.obFilterData)) {
      // console.trace('ARE THE SAME');
      return;
    }

    debug("Updated filters: ", obFilterData);
    debug("Current filters: ", this.obFilterData);

    this.replaceFilter(obFilterData);
    this.$emit("update:filters", obFilterData);
  }

  async download(sFormat: ReportFormat = "pdf") {
    if (!this.canDownload) {
      return;
    }

    try {
      // Set loading button
      this.downloading = sFormat;

      const obFiltersData = { ...this.obFilterData };
      EventBus.emit("report.before.apply", obFiltersData);

      if (sFormat === "xls" && this.sXlsUrl && this.sFilename) {
        await Utils.downloadUrl(this.sXlsUrl, this.sFilename);
        return;
      }

      const obReport = useReport(this.report, sFormat);
      const arColumns = map(this.headers, "value");
      obReport.filters(obFiltersData);
      obReport.columns(arColumns);
      await obReport.download(this.reportFilename, sFormat, () => {
        this.downloading = null;
      });
    } catch (e) {
      console.error(e);
      this.downloading = null;
    }
  }

  format(fValue: number, obItem: InvoiceData | Record<string, any>) {
    return currencyFormat(fValue, get(obItem, "currency.code", "UYU"), 2, true);
  }

  updateTableKey() {
    this.sTableKey = uniqueId(`report-${this.report}`);
  }
}
</script>
